<template>
  <section>
    <h2>Pieejamie īpašumi</h2>
  </section>
  <section>
    <Apartment v-for="(data, index) in apdata" v-bind:key="index" v-bind:apdata="data" />
  </section>
  <hr/>
  <section>
    <h3>Kontakti</h3>
    <p>
      Ieinteresēja kāds no piedāvajumiem? Zvaniet mums - <strong>tel. {{phone}}</strong>
      vai rakstiet uz <a href="{{email}}">{{email}}</a>
    </p>
  </section>

</template>

<script>
// @ is an alias to /src
import Apartment from '@/components/Apartment.vue';

export default {
  name: 'Home',
  components: {
    Apartment,
  },
  data() {
    return {
      email: 'info@gaisatilts.lv',
      phone: '20022872',
      apdata: [],
    };
  },
  created() {
    fetch('/data.json')
      .then((r) => r.json())
      .then((json) => {
        this.apdata = json;
      });
  },
};
</script>
