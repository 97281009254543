<template>
    <aside>
        <carousel>
          <slide v-for="img in apdata.images" v-bind:key="img">
            <img :src="'images/' + img" />
          </slide>
          <template #addons>
            <navigation />
            <pagination />
          </template>
        </carousel>
        <h4>{{ apdata.description }}</h4>
        <p class="details">
          <span class="price">{{ apdata.price }}</span>
          <span class="size">{{ apdata.size }}m<sup>2</sup></span>
          <span class="roomcount">telpas: {{ apdata.rooms }}</span>
        </p>
    </aside>
</template>

<script>
import 'vue3-carousel/dist/carousel.css';
import {
  Carousel, Slide, Pagination, Navigation,
} from 'vue3-carousel';

export default {
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
  name: 'Apartment',
  props: {
    apdata: Object,
  },
};
</script>

<style lang="scss">
  p.details {
    display: flex;
    justify-content: space-evenly;
    align-items: baseline;

    span {
      display: block;

      &.size {
        sup {
          background-color: transparent;
          color: #000;
          padding: 0;
          margin: 0;
        }
      }
      &.price {
        font-size: 1.3em;
        color: var(--color-secondary);
      }

    }
  }
  .carousel__pagination {
    position: absolute;
    bottom: 5px;
    margin-bottom: 0px;
    width: 100%;
    padding-left: 0px;

    button {
      padding: 0;
    }
  }

</style>
