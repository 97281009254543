<template>
  <header>
      <nav>
          <a href="/"><img src="./assets/gt-logo.svg" width="300" height="173"/></a>
      </nav>
      <div class="bgimg">
        <h1>Telpas birojiem un dzīvokļi VEF rajonā - Brīvības gatvē 195</h1>
      </div>
  </header>
  <main>
    <router-view/>
  </main>
  <footer>
      <hr/>
      <p>&copy; SIA "Gaisa Tilts"</p>
  </footer>
</template>

<style lang="scss">
header {

    nav {
        flex-direction: column;
        margin-bottom: 1rem;
    }

    .bgimg {
        display: flex;
        background: url('./assets/brivibas195.jpg');
        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-size: cover;
        min-height: 250px;
        // border: 1px solid white;
        // box-shadow: 0px 0px 6px rgb(0 0 0 / 20%);
        // border-radius: 7px;
        border: 1px solid var(--color-bg-secondary);
        border-radius: var(--border-radius);
        box-shadow: var(--box-shadow) var(--color-dark-shadow);
        align-items: center;
        justify-content: center;

        h1 {
            color: #fff;
            font-size: 2.5em;
            letter-spacing: -0.04em;
            text-shadow: 0 0 5px rgb(0 0 0 / 50%);
        }
    }

}
</style>
